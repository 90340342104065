import { Appplications } from './Applications'
import { DataProcessing } from './DataProcessing'
import { Favorites } from './Favorites'
import { ServerDown } from './ServerDown'

export const Illustrations = {
  applications: Appplications,
  dataProcessing: DataProcessing,
  favorites: Favorites,
  serverDown: ServerDown
}
